import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-landing-page',
  templateUrl: './teammates.component.html',
  styleUrls: ['./teammates.component.sass']
})
export class teammatesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  home(): void {
    this.router.navigateByUrl('pages/landing-page');
  }
  about(): void {
    this.router.navigateByUrl('pages/about');
  }
  schedule(): void {
    this.router.navigateByUrl('pages/schedule');
  }
 
  teammates(): void {
    this.router.navigateByUrl('pages/teammates');
  }

  FQA(): void {
    this.router.navigateByUrl('pages/FAQ');
  }

  contactus(): void {
    this.router.navigateByUrl('pages/contactus');
  }
}
