import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { aboutComponent } from './pages/about/about.component';
import { scheduleComponent } from './pages/schedule/schedule.component';
import { teammatesComponent } from './pages/teammates/teammates.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FAQComponent } from './pages/FAQ/FAQ.component';
import { contactusComponent } from './pages/contactus/contactus.component';
// tslint:disable-next-line: max-line-length





// import { UserRegistrationS1Component } from './pages/user-registration/user-registration-s1.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,

    
    aboutComponent,
    scheduleComponent,
    teammatesComponent,
    FAQComponent,
    contactusComponent,
    


    // UserRegistrationS1Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
