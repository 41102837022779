import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { aboutComponent } from './pages/about/about.component';
import { scheduleComponent } from './pages/schedule/schedule.component';
import { teammatesComponent } from './pages/teammates/teammates.component';
import { FAQComponent } from './pages/FAQ/FAQ.component';
import { contactusComponent } from './pages/contactus/contactus.component';




const routes: Routes = [
  {
    path: 'pages/landing-page',
    component: LandingPageComponent
  },
  {
    path: 'pages/about',
    component: aboutComponent
  },

  {
  path: 'pages/schedule',
  component: scheduleComponent
  },
  {
    path: 'pages/teammates',
    component: teammatesComponent
  },
  {
    path: 'pages/FAQ',
    component: FAQComponent
  }, 
  {
    path: 'pages/contactus',
    component: contactusComponent
  }, 
     
  { path: '', redirectTo: 'pages/landing-page', pathMatch: 'full' },
  { path: '', redirectTo: 'pages/about', pathMatch: 'full' },
  { path: '', redirectTo: 'pages/schedule', pathMatch: 'full' },
  { path: '', redirectTo: 'pages/teammates', pathMatch: 'full' },
  { path: '', redirectTo: 'pages/FAQ', pathMatch: 'full' },
  { path: '', redirectTo: 'pages/contactus', pathMatch: 'full' },
  // { path: 'pages/user-registration', component: UserRegistrationComponent },
 
  


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
