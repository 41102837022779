import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-landing-page',
  templateUrl: './FAQ.component.html',
  styleUrls: ['./FAQ.component.sass']
})
export class FAQComponent implements OnInit {
    isShow = true;
    isShow1 = true;
    isShow2 = true;
    isShow3 = true;
    isShow4 = true;
    isShow5 = true;
    isShow6 = true;
    isShow7 = true;
    isShow8 = true;
    isShow9= true;
    isShow10 = true;
    isShow11 = true;
    isShow12 = true;
    isShow13 = true;
    isShow14 = true;
    isShow15 = true;
    
    
    toggleDisplay
    () {
      this.isShow = !this.isShow;
    }
      toggleDisplay1() {
        this.isShow1 = !this.isShow1;
    }
  
  toggleDisplay2() {
    this.isShow2 = !this.isShow2;

}
toggleDisplay3() {
  this.isShow3 = !this.isShow3;

}
toggleDisplay4() {
  this.isShow4 = !this.isShow4;
}
toggleDisplay5() {
  this.isShow5 = !this.isShow5;
}
toggleDisplay6() {
  this.isShow6 = !this.isShow6;
}
toggleDisplay7() {
  this.isShow7 = !this.isShow7;
}
toggleDisplay8() {
  this.isShow8 = !this.isShow8;
}
toggleDisplay9() {
  this.isShow9 = !this.isShow9;
}
toggleDisplay10() {
  this.isShow10 = !this.isShow10;
}
toggleDisplay11() {
  this.isShow11 = !this.isShow11;
}
toggleDisplay12() {
  this.isShow12 = !this.isShow12;
} 
toggleDisplay13() {
  this.isShow13 = !this.isShow13;
}
toggleDisplay14() {
  this.isShow14 = !this.isShow14;
}
toggleDisplay15() {
  this.isShow15 = !this.isShow15;
}

  constructor(private router: Router) { }

  ngOnInit() {
  }
  home(): void {
    this.router.navigateByUrl('pages/landing-page');
  }
  about(): void {
    this.router.navigateByUrl('pages/about');
  }
  schedule(): void {
    this.router.navigateByUrl('pages/schedule');
  }
 
  teammates(): void {
    this.router.navigateByUrl('pages/teammates');
  }

  FQA(): void {
    this.router.navigateByUrl('pages/FAQ');
  }

  contactus(): void {
    this.router.navigateByUrl('pages/contactus');
  }
}
